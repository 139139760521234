import Header from "./components/common/Header";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Blockchain from "./pages/Blockchain";
import Publishers from "./pages/Publishers";
import Resources from "./pages/Resources";
import { WebsocketProvider } from "./hooks/useWebSocketClient";
function App() {
  return (
    <div>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/blockchain" element={<Blockchain />} />
        <Route path="/publishers" element={<Publishers />} />
        <Route path="/resources" element={<Resources />} />
      </Routes>
    </div>
  );
}

export default () => (
  <WebsocketProvider>
    <App />
  </WebsocketProvider>
);
