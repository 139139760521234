import { randomBytes } from "crypto";

export class ControlMessageClient {
  public readonly api: string;

  private sock: WebSocket | undefined;

  constructor(api: string) {
    this.api = api;
  }

  async connect(): Promise<void> {
    const ws = new WebSocket(this.api);
    ws.binaryType = "arraybuffer";
    this.sock = ws;

    return new Promise((resolve) => {
      ws.addEventListener("open", function (event) {
        resolve();
      });
    });
  }

  disconnect(): void {
    this.sock?.close();
  }

  async request<T>(mesg: any): Promise<T> {
    if (!this.sock) await this.connect();

    const requestId = randomBytes(16).toString("hex");
    const sock = this.sock;

    return new Promise((resolve, reject) => {
      const onMessage = (mesg: any) => {
        //@ts-ignore
        const response = JSON.parse(new TextDecoder().decode(mesg.data));
        if (!sock) return reject();

        if (response.requestId !== requestId) return;
        if (response.payload.status === "error") {
          reject(response.payload.error);
        }

        resolve(response.payload);
      };

      if (!sock) return;
      sock.addEventListener("message", onMessage);
      sock.send(JSON.stringify({ requestId, ...mesg }));
    });
  }
}
