import React from "react";

export default function SearchBox({
  searchText,
  setSearchText,
}: ISearchBoxProps) {
  return (
    <form className="mr-2.5">
      <input
        className="w-[314px] h-[42px] font-roboto text-base text-ink-light border border-background rounded px-4 py-2"
        type="text"
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
      />
    </form>
  );
}

interface ISearchBoxProps {
  searchText: string;
  setSearchText: any;
}
