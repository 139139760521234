import React, { useState } from "react";
import SearchBox from "../common/SearchBox";

export default function History() {
  const [searchText, setSearchText] = useState("Search transactions");
  return (
    <div className="mt-[74px] font-roboto history">
      <div className="flex items-center justify-between">
        <h3 className="font-medium text-lg mt-0">History</h3>
        <div className="search">
          <div className="flex">
            <SearchBox searchText={searchText} setSearchText={setSearchText} />

            <button className="py-2 px-6 text-base text-white font-medium bg-violet-dark rounded">
              Search
            </button>
          </div>
        </div>
      </div>

      <p className="text-base text-ink-lighter mt-[22px]">
        Showing 1-10 out of 100 records
      </p>
      <table className="mt-5 w-full text-left">
        <thead className="text-base text-ink-light  bg-background-lightest uppercase py-3 h-12">
          <tr
            className="font-medium border border-background-lighter"
            style={{ borderRadius: "4px 4px 0 0" }}
          >
            <th className="pl-6 font-medium pr-[140px]">txn hash</th>
            <th className="font-medium">txn type</th>
            <th className="font-medium">Time</th>
            <th className="font-medium">from</th>
            <th></th>
            <th className="font-medium">To</th>
            <th className="font-medium">asset</th>
          </tr>
        </thead>
        <tbody className="border rounded-b">
          <tr className="rounded-b">
            <td className="pl-6 py-[25px]">0x80bf3a234b751494...</td>
            <td>Transfer</td>
            <td>6 Mar 2022 10:12:45 GMT</td>
            <td>0x80bf3a234b751494...</td>
            <td>`{"->"}`</td>
            <td>0x80bf3a234b751494...</td>
            <td>999,999.999999</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
