import React, { useState } from "react";
import ChevronDown from "../../assets/ChevronDown";

export default function Dropdown({
  selected,
  setSelected,
  options,
}: IDropdownProps) {
  const [isActive, setIsActive] = useState(false);
  return (
    <div className="dropdown w-[142px] h-[40px] select-none relative font-roboto text-base text-ink-light mr-3">
      <div
        className="dropdown-btn px-4 py-2 bg-white border border-background rounded  flex items-center justify-between"
        onClick={(e) => setIsActive(!isActive)}
      >
        {selected}
        <ChevronDown />
      </div>

      {isActive && (
        <div className="dropdown-content absolute top-[110%] p-4 bg-white border border-background rounded w-[100%] mt-1">
          {options.map((option) => (
            <div
              className="dropdown-item cursor-pointer transition-all hover:bg-body mb-1"
              onClick={(e) => {
                setSelected(e.currentTarget.textContent);
                setIsActive(false);
              }}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

interface IDropdownProps {
  selected: string;
  setSelected: any;
  options: string[];
}
