import React, { useState } from "react";
import Dropdown from "../components/common/Dropdown";
import SearchBox from "../components/common/SearchBox";
import Assets from "../components/home/Assets";
import Overview from "../components/home/Overview";
import Tabs from "../components/home/Tabs";

const tabs = [
  {
    id: 1,
    title: "Overview",
    component: <Overview />,
  },
  {
    id: 2,
    title: "Assets",
    component: <Assets />,
  },
];

export default function Home() {
  const [selected, setSelected] = useState("All filters");
  const [searchText, setSearchText] = useState("Search");

  const options: string[] = ["BTC", "ETH", "BNB"];
  return (
    <div className="pt-[51px] px-20 pb-[122px] bg-body">
      <div className="flex items-center justify-between">
        <h1 className="text-xl font-roboto text-black">Lohko Gold assets</h1>
        <div className="filter flex items-center justify-between">
          <Dropdown
            selected={selected}
            setSelected={setSelected}
            options={options}
          />
          <SearchBox searchText={searchText} setSearchText={setSearchText} />
          <button className="bg-violet-dark py-2 px-6 text-white font-roboto font-medium text-base rounded">
            Search
          </button>
        </div>
      </div>
      <h2 className="text-md text-ink-light font-roboto mt-2.5">
        Asset Backed
      </h2>
      <Tabs tabs={tabs} />
    </div>
  );
}
