import React from "react";
import { ITabs } from "./Tabs";

export default function Navigation(props: INavigationProps) {
  const { tabs, onNavClick, activeTabId } = props;
  return (
    <ul
      className={`tabs__nav flex text-base text-black font-roboto w-full border-b border-background-lighter`}
    >
      {tabs.map((item: any) => (
        <li key={item.id} className={`tabs__item mr-6`}>
          <button
            className={`tabs__button ${
              activeTabId === item.id
                ? "active border-b-2 border-violet-dark pb-2 mb-[-1px]"
                : ""
            }`}
            onClick={() => onNavClick(item.id)}
          >
            {item.title}
          </button>
        </li>
      ))}
    </ul>
  );
}

interface INavigationProps {
  tabs: ITabs[];
  onNavClick: any;
  activeTabId: number;
}
