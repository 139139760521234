import React from "react";

export default function Telegram() {
  return (
    <svg
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.724 1.45659L16.845 15.0342C16.6278 15.9924 16.0613 16.2309 15.2564 15.7795L10.8697 12.5469L8.75296 14.5827C8.51871 14.817 8.3228 15.0129 7.87135 15.0129L8.18651 10.5452L16.3169 3.19851C16.6704 2.88334 16.2402 2.70873 15.7675 3.02389L5.71632 9.35271L1.38921 7.99836C0.447977 7.70449 0.430941 7.05713 1.58512 6.60568L18.5102 0.0852048C19.2939 -0.208664 19.9796 0.259822 19.724 1.45659Z"
        fill="black"
      />
    </svg>
  );
}
