import constate from "constate";
import { useEffect, useState } from "react";
import { ControlMessageClient } from "../adapters/websocket";
import { ControlMessageType } from "../types/ControlMessageTypes";

const API_URL = "wss://web3.link/api/i";

const getClaimsIntervalCheck = 10000;

const useWebSocketClient = () => {
  const [webSocketClient, setWebSocketClient] =
    useState<ControlMessageClient>();
  const [claims, setClaims] = useState<any>();
  useEffect(() => {
    const client = new ControlMessageClient(API_URL);
    setWebSocketClient(client);
  }, []);

  useEffect(() => {
    if (webSocketClient) {
      getClaimsStates();
      const interval = setInterval(() => {
        getClaimsStates();
      }, getClaimsIntervalCheck);
      return () => clearInterval(interval);
    }
  }, [webSocketClient]);

  const getClaimsStates = async () => {
    const claims = await webSocketClient?.request({
      type: ControlMessageType.GET_CLAIM_STATES,
    });
    console.log(claims);
    setClaims(claims);
  };

  const functionCreate = async ({ fakeIt }: { fakeIt: string }) => {
    const { requestId } = await webSocketClient?.request<any>({
      type: ControlMessageType.CREATE_FUNCTION,
      payload: {
        name: "n",
        app: "bafybeicbt6pp4bizz3mqil7imsrqhpk5tzdfmtdnntgi3b623lp4ev4nva",
        court: "bafybeid3qshzbt7eldryicgme4rkd2iq6bff7tjpk7qip3flcbm7xfe7j4",
        state: "bafkreiberljl3vazfsomlqrk4pslijsjv5mptyloob4vbub25a4dpn6hn4",
        input: "bafkreiayj7srtull2omukmefufyiuhsmbdem6ahj6g4yhoch6deoxl2v6a",
        scratch: "bafybeih5rskpjakvwtjshywfjapmu7uwsl54ye3xa47j5izsakqki2i3ay",
        fakeIt,
      },
    });

    return requestId;
  };
  return { functionCreate, claims, getClaimsStates };
};

// 2️⃣ Wrap your hook with the constate factory
const [WebsocketProvider, useWebSocketContext] = constate(useWebSocketClient);

export { WebsocketProvider, useWebSocketContext };
