import React, { useEffect, useState } from "react";
import Github from "../../assets/Github";
import Info from "../../assets/Info";
import LinkedIn from "../../assets/LinkedIn";
import Spinner from "../../assets/Spinner";
import Telegram from "../../assets/Telegram";
import Twitter from "../../assets/Twitter";
import { useWebSocketContext } from "../../hooks/useWebSocketClient";
import History from "./History";

enum STATES {
  started = "started",
  notYetStarted = "not-yet-disputed",
  error = "error",
  disputed = "disputed",
  disputeStarted = "dispute-started",
  fraudByClaimer = "fraud-by-claimer",
  correct = "correct",
}
const stateMessages = {
  [STATES.started]: "Running the data verification on remote node",
  [STATES.notYetStarted]: "Sent to network, not yet disputed",
  [STATES.error]: "Error: Input was not accepted by audit function",
  [STATES.disputed]: "A node has objected on-chain",
  [STATES.disputeStarted]: "The dispute has started on-chain",
  [STATES.fraudByClaimer]: "Dispute result: Database history, data, or signatures do not match. ",
  [STATES.correct]: "Success",
};

const UploadDataComponent = ({ onClose, error, onCreateFake }: any) => {
  return (
    <div
      id="defaultModal"
      tabIndex={-1}
      aria-hidden="true"
      className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full flex items-center justify-center	"
    >
      <div className="relative p-4 w-full max-w-3xl h-700 md:h-auto">
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
          <div className="flex justify-between items-start p-5 rounded-t border-b dark:border-gray-600">
            <h3 className="text-xl font-semibold text-gray-900 lg:text-2xl dark:text-white text-center">
              The node found a mismatch in the data and rejected the upload
            </h3>
            <button
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
              data-modal-toggle="defaultModal"
              onClick={onClose}
            >
              <svg
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </button>
          </div>

          <div className="p-6 space-y-6 max-h-80 overflow-y-auto">
            <pre className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
              {error}
            </pre>
          </div>

          <div className="flex items-center p-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600">
            <button
              data-modal-toggle="defaultModal"
              type="button"
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-1 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              onClick={onCreateFake}
            >
              Force upload
            </button>
            <button
              data-modal-toggle="defaultModal"
              type="button"
              className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-1 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
              onClick={onClose}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
interface IClaimStatus {
  state: STATES;
  date: string;
}
const DataOverview = () => {
  const [isClaimInProgress, setIsClaimInProgress] = useState(true);
  const [claimId, setClaimId] = useState();
  const [claimError, setClaimError] = useState("");
  const [claimStatus, setClaimStatus] = useState<IClaimStatus[]>([]);

  const [isFakeUploadDataOpen, setIsFakeUploadDataOpen] = useState(false);

  const { functionCreate, claims } = useWebSocketContext();

  const onCreateFake = async () => {
    const claimId = await functionCreate({ fakeIt: "true" });
    setClaimId(claimId);
    const statuses = [
      {
        state: STATES.started,
        date: new Date().toLocaleString(),
      },
    ];
    setClaimStatus(statuses);
    setIsFakeUploadDataOpen(false);
    setIsClaimInProgress(true);
  };
  const onCreate = async () => {
    setIsClaimInProgress(true);
    const claimId = await functionCreate({ fakeIt: "false" });
    const statuses = [
      {
        state: STATES.started,
        date: new Date().toLocaleString(),
      },
    ];
    setClaimStatus(statuses);
    setClaimId(claimId);
  };

  useEffect(() => {
    if (claimId && claims && Object.keys(claims).length && isClaimInProgress) {
      const claimKey = Object.keys(claims).find((claimNumber) =>
        claimNumber.includes(claimId)
      );

      if (claimKey) {
        const claim = claims[claimKey];
        if (claim.includes("error:")) {
          setClaimError(claim);
          setIsFakeUploadDataOpen(true);

          setClaimStatus([
            ...claimStatus,
            { state: STATES.error, date: new Date().toLocaleString() },
          ]);
          setIsClaimInProgress(false);
        }
        if (claim.includes("cid:")) {
          const id = claim.split("cid:")[1];
          if (claims[id]) {
            const claimObject = claims[id];
            const state = claimObject.taskState;
            if (!claimStatus.find((status: any) => status.state === state)) {
              setClaimStatus([
                ...claimStatus,
                { state: state, date: new Date().toLocaleString() },
              ]);
            }
            if (
              claimObject.taskState == STATES.fraudByClaimer ||
              claimObject.taskState === STATES.correct
            ) {
              setIsClaimInProgress(false);
            }
          }
        }
      }
    }
  }, [claims, claimId, isClaimInProgress]);

  return (
    <div className="data-overview border border-background-lighter pt-3 rounded w-[619px] h-[381px] relative mr-[42px] overflow-y-auto">
      {isFakeUploadDataOpen && (
        <UploadDataComponent
          onClose={() => setIsFakeUploadDataOpen(false)}
          error={claimError}
          onCreateFake={onCreateFake}
        />
      )}
      <h3 className="border-b text-base font-medium border-background-lighter pl-6 pb-3 ">
        Data Overview
      </h3>
      {claimId ? (
        <div className="pl-6">
          <div className="pt-5">
            <div className="flex items-center">
              <h5 className="text-ink-lighest text-2xs uppercase mr-2">
                Running on node:
              </h5>
            </div>

            <p className="mt-0.5 text-base text-violet-dark">{claimId}</p>
          </div>
          {claimStatus.map((status: IClaimStatus) => (
            <div className="pt-5" key={status.date}>
              <h5 className="text-ink-lighest text-2xs uppercase">
                {status.date}
              </h5>
              <p className="mt-0.5 text-base text-violet-dark">
                {stateMessages[status.state] || status.state}
              </p>
            </div>
          ))}
          {isClaimInProgress && (
            <div className="mt-4">
              <Spinner />
            </div>
          )}{" "}
        </div>
      ) : (
        <div className="p-3 flex flex-col justify-center items-center	h-full">
          <h5 className="text-ink-lighest text-2xs ">
            No data set has been accepted by this function yet
          </h5>
          <button
            className="mt-4 block text-white bg-blue-700 hover:bg-blue-800 focus:ring-1 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            type="button"
            data-modal-toggle="defaultModal"
            onClick={onCreate}
          >
            Upload data
          </button>
        </div>
      )}
    </div>
  );
};

export default function Overview() {
  return (
    <div className="mt-11 font-roboto">
      <div className="overview flex">
        <DataOverview />
        <div className="publisher-profile border border-background-lighter pt-3 rounded w-[619px] h-[381px]">
          <h3 className="border-b text-base font-medium border-background-lighter pl-6 pb-3 ">
            Publisher Profile
          </h3>

          <div className="pl-6">
            <div className="pt-5">
              <h5 className="text-ink-lighest text-2xs uppercase">Publisher</h5>
              <p className="mt-0.5 text-base text-black">
                Lohko Wallet Pte Ltd
              </p>
            </div>
            <div className="flex mt-7">
              <div className="mr-[66px]">
                <h5 className="text-ink-lighest text-2xs uppercase">
                  official site
                </h5>
                <a
                  href="https://lohkowallet.com"
                  target="_blank"
                  className="text-base text-violet-dark"
                  rel="noreferrer"
                >
                  www.lohkowallet.com
                </a>
              </div>

              <div className="social-profile">
                <h5 className="text-ink-lighest text-2xs uppercase">
                  Social profiles
                </h5>
                <div className="social-links mt-3.5 flex items-center justify-between">
                  <a
                    className="mr-5"
                    href="https://twitter.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Twitter />
                  </a>
                  <a
                    className="mr-5"
                    href="https://telegram.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Telegram />
                  </a>
                  <a
                    className="mr-5"
                    href="https://github.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Github />
                  </a>
                  <a
                    href="https://linkedin.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <LinkedIn />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex mt-[49px]">
        <div className="border border-background-lighter w-[824px] h-[234px] rounded mr-[42px] verifications">
          <h3 className="border-b text-base font-medium border-background-lighter pl-6 py-3">
            Verifications
          </h3>
          <div className="pl-6 pt-5">
            <div className="flex">
              <div className="mr-[59px]">
                <div className="flex items-center">
                  <h5 className="text-ink-lighest text-2xs uppercase mr-[5px]">
                    Verification Frequency
                  </h5>
                  <Info />
                </div>
                <p className="font-normal mt-0.5">Weekly</p>
              </div>
              <div className="mr-[59px]">
                <div className="flex items-center">
                  <h5 className="text-ink-lighest text-2xs uppercase mr-[5px]">
                    Last verification
                  </h5>
                  <Info />
                </div>
                <p className="font-normal mt-0.5">10 March 2022 09:43:24 GMT</p>
              </div>
              <div>
                <div className="flex items-center">
                  <h5 className="text-ink-lighest text-2xs uppercase mr-[5px]">
                    Publisher Staking
                  </h5>
                  <Info />
                </div>
                <p className="font-normal mt-0.5">500 000 ZIPT</p>
              </div>
            </div>

            <div className="flex mt-7">
              <div className="mr-[59px]">
                <h5 className="text-ink-lighest text-2xs uppercase mr-[5px]">
                  availability score
                </h5>
                <p className="text-md mt-0.5 text-teal-dark font-medium">99%</p>
              </div>
              <div className="mr-[59px]">
                <h5 className="text-ink-lighest text-2xs uppercase mr-[5px]">
                  history match score
                </h5>
                <p className="text-md mt-0.5 text-teal-dark font-medium">
                  100%
                </p>
              </div>
              <div>
                <h5 className="text-ink-lighest text-2xs uppercase mr-[5px]">
                  signature verification score
                </h5>
                <p className="text-md mt-0.5 text-teal-dark font-medium">
                  100%
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="border border-background-lighter w-[414px] h-[234px] rounded disputes">
          <h3 className="border-b text-base font-medium border-background-lighter pl-6 py-3">
            Disputes
          </h3>

          <div className="pl-6 mt-5">
            <div className="flex">
              <div className="mr-12">
                <h5 className="text-ink-lighest text-2xs uppercase mr-[5px]">
                  All time disputes
                </h5>
                <p className="font-normal mt-0.5">5</p>
              </div>
              <div>
                <h5 className="text-ink-lighest text-2xs uppercase mr-[5px]">
                  publisher success rate
                </h5>

                <div className="flex items-baseline mt-0.5">
                  <p className="text-md text-teal-dark font-medium mr-4">
                    100%
                  </p>

                  <span className="text-2xs text-ink-light">5/5</span>
                </div>
              </div>
            </div>

            <div className="flex mt-7">
              <div className="mr-[59px]">
                <h5 className="text-ink-lighest text-2xs uppercase">
                  Open disputes
                </h5>
                <p className="font-normal mt-0.5">0</p>
              </div>

              <div>
                <h5 className="text-ink-lighest text-2xs uppercase">
                  disputes in the last 7 days
                </h5>
                <p className="font-normal mt-0.5">0</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <History />
    </div>
  );
}

interface IOverview {
  dataContract: string;
  totalAmount: number;
  weeklyChange: number;
  numberOfHolders: number;
  blockchain: string;
  tokenStandard: string;
  assetType: string;
  digitalSignature: string;
}
