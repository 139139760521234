import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/Logo";

export default function Header() {
  return (
    <header className="font-roboto h-[80px] py-6 px-20 bg-white flex justify-between shadow-sm items-center">
      <div className="logo flex items-end">
        <Logo />
        <div className="ml-1 font-noto text-ink text-xs opacity-70">
          Asset Scan
        </div>
      </div>
      <div className="nav-links font-roboto text-base text-black">
        <Link className="mr-12" to="/">
          Home
        </Link>
        <Link className="mr-12" to="/blockchain">
          Blockchain
        </Link>
        <Link className="mr-12" to="/publishers">
          Publishers
        </Link>
        <Link to="/resources">Resources</Link>
      </div>
      <button className="bg-teal py-2 px-[22px] text-white font-roboto font-medium text-base rounded">
        For publishers
      </button>
    </header>
  );
}
