import React from "react";

export default function LinkedIn() {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.76992 16H0.452707V5.31762H3.76992V16ZM2.10953 3.86044C1.04879 3.86044 0.188416 2.98185 0.188416 1.92111C0.188416 1.4116 0.390818 0.92296 0.751097 0.562681C1.11138 0.202403 1.60002 0 2.10953 0C2.61904 0 3.10768 0.202403 3.46796 0.562681C3.82824 0.92296 4.03064 1.4116 4.03064 1.92111C4.03064 2.98185 3.16991 3.86044 2.10953 3.86044ZM16.1852 16H12.8751V10.7999C12.8751 9.56057 12.8501 7.97125 11.1504 7.97125C9.42576 7.97125 9.16147 9.31771 9.16147 10.7106V16H5.84783V5.31762H9.02933V6.77479H9.07576C9.51862 5.93549 10.6004 5.04976 12.2144 5.04976C15.5716 5.04976 16.1888 7.26052 16.1888 10.132V16H16.1852Z"
        fill="black"
      />
    </svg>
  );
}
