import React, { useMemo, useState } from "react";
import Navigation from "./Navigation";
import Tab from "./Tab";

export default function Tabs(props: ITabsProps) {
  const { tabs } = props;
  const [activeTabId, setActiveTab] = useState(tabs[0].id);

  const activeTab = useMemo(
    () => tabs.find((tab: ITabs) => tab.id === activeTabId),
    [activeTabId, tabs]
  );

  return (
    <div className={`tabs mt-9`}>
      <Navigation
        tabs={tabs}
        onNavClick={setActiveTab}
        activeTabId={activeTabId}
      />
      <Tab tab={activeTab} />
    </div>
  );
}

export interface ITabsProps {
  tabs: ITabs[];
}

export interface ITabs {
  id: number;
  title: string;
  component: JSX.Element;
}
