export enum ControlMessageType {
    GET_ACCOUNT_INFO = "get-account-info",
    GET_EPOCH_PROOF = "get-epoch-proof",
    GET_MERKLE_PROOF_FOR_WITHDRAW = "get-merkle-proof-for-withdrawal",
    GET_NODE_INFO = "get-node-info",
    CREATE_FUNCTION = "create-func",
    EXECUTE_FUNCTION = "execute-func",
    SUBMIT_TASK = "submit-task",
    SUBMIT_TX = "submit-tx",
    SUBMIT_SIGNED_TX = "submit-signed-tx",
    GET_CLAIM_STATES = "get-claim-states",
  }
  export interface ControlMessage<MessageType, PayloadType = unknown> {
    requestId: string;
    type: MessageType;
    payload: PayloadType;
  }
  

  export type CreateFunctionMessage = ControlMessage<
  ControlMessageType.CREATE_FUNCTION,
  {
    name: string;
    app: string;
    court: string;
    state: string;
    input: string;
    scratch: string;
    fakeIt: string;
  }
>;