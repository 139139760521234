import React from "react";

export default function Twitter() {
  return (
    <svg
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.675 3.98749C17.6875 4.16248 17.6875 4.33751 17.6875 4.5125C17.6875 9.84997 13.625 16 6.20001 16C3.91249 16 1.78751 15.3375 0 14.1875C0.325012 14.225 0.637481 14.2375 0.974997 14.2375C2.86247 14.2375 4.59999 13.6 5.9875 12.5125C4.2125 12.475 2.72499 11.3125 2.21248 9.7125C2.4625 9.74997 2.71249 9.77498 2.97501 9.77498C3.3375 9.77498 3.70003 9.72496 4.03751 9.63751C2.18751 9.26247 0.799967 7.63749 0.799967 5.67499V5.62501C1.33745 5.92501 1.9625 6.11251 2.62495 6.13748C1.53745 5.41247 0.824977 4.17498 0.824977 2.77497C0.824977 2.02499 1.02494 1.33749 1.37496 0.737481C3.36247 3.18748 6.34999 4.78745 9.69995 4.96248C9.63747 4.66248 9.59995 4.35001 9.59995 4.03751C9.59995 1.81248 11.4 0 13.6375 0C14.8 0 15.8499 0.487499 16.5875 1.275C17.4999 1.10001 18.3749 0.762491 19.15 0.300002C18.8499 1.23752 18.2124 2.02502 17.375 2.52499C18.1875 2.43753 18.975 2.21248 19.6999 1.90001C19.15 2.69998 18.4625 3.41245 17.675 3.98749Z"
        fill="black"
      />
    </svg>
  );
}
